import * as _fs2 from "fs";

var _fs = "default" in _fs2 ? _fs2.default : _fs2;

import * as _path2 from "path";

var _path = "default" in _path2 ? _path2.default : _path2;

import _buffer from "buffer";
var exports = {};
var Buffer = _buffer.Buffer;
var fs = _fs;
var path = _path;
Object.defineProperty(exports, "commentRegex", {
  get: function getCommentRegex() {
    return /^\s*\/(?:\/|\*)[@#]\s+sourceMappingURL=data:(?:application|text)\/json;(?:charset[:=]\S+?;)?base64,(?:.*)$/mg;
  }
});
Object.defineProperty(exports, "mapFileCommentRegex", {
  get: function getMapFileCommentRegex() {
    // Matches sourceMappingURL in either // or /* comment styles.
    return /(?:\/\/[@#][ \t]+sourceMappingURL=([^\s'"`]+?)[ \t]*$)|(?:\/\*[@#][ \t]+sourceMappingURL=([^\*]+?)[ \t]*(?:\*\/){1}[ \t]*$)/mg;
  }
});
var decodeBase64;

if (typeof Buffer !== "undefined") {
  if (typeof Buffer.from === "function") {
    decodeBase64 = decodeBase64WithBufferFrom;
  } else {
    decodeBase64 = decodeBase64WithNewBuffer;
  }
} else {
  decodeBase64 = decodeBase64WithAtob;
}

function decodeBase64WithBufferFrom(base64) {
  return Buffer.from(base64, "base64").toString();
}

function decodeBase64WithNewBuffer(base64) {
  if (typeof value === "number") {
    throw new TypeError("The value to decode must not be of type number.");
  }

  return new Buffer(base64, "base64").toString();
}

function decodeBase64WithAtob(base64) {
  return decodeURIComponent(escape(atob(base64)));
}

function stripComment(sm) {
  return sm.split(",").pop();
}

function readFromFileMap(sm, dir) {
  // NOTE: this will only work on the server since it attempts to read the map file
  var r = exports.mapFileCommentRegex.exec(sm); // for some odd reason //# .. captures in 1 and /* .. */ in 2

  var filename = r[1] || r[2];
  var filepath = path.resolve(dir, filename);

  try {
    return fs.readFileSync(filepath, "utf8");
  } catch (e) {
    throw new Error("An error occurred while trying to read the map file at " + filepath + "\n" + e);
  }
}

function Converter(sm, opts) {
  opts = opts || {};
  if (opts.isFileComment) sm = readFromFileMap(sm, opts.commentFileDir);
  if (opts.hasComment) sm = stripComment(sm);
  if (opts.isEncoded) sm = decodeBase64(sm);
  if (opts.isJSON || opts.isEncoded) sm = JSON.parse(sm);
  this.sourcemap = sm;
}

Converter.prototype.toJSON = function (space) {
  return JSON.stringify(this.sourcemap, null, space);
};

if (typeof Buffer !== "undefined") {
  if (typeof Buffer.from === "function") {
    Converter.prototype.toBase64 = encodeBase64WithBufferFrom;
  } else {
    Converter.prototype.toBase64 = encodeBase64WithNewBuffer;
  }
} else {
  Converter.prototype.toBase64 = encodeBase64WithBtoa;
}

function encodeBase64WithBufferFrom() {
  var json = this.toJSON();
  return Buffer.from(json, "utf8").toString("base64");
}

function encodeBase64WithNewBuffer() {
  var json = this.toJSON();

  if (typeof json === "number") {
    throw new TypeError("The json to encode must not be of type number.");
  }

  return new Buffer(json, "utf8").toString("base64");
}

function encodeBase64WithBtoa() {
  var json = this.toJSON();
  return btoa(unescape(encodeURIComponent(json)));
}

Converter.prototype.toComment = function (options) {
  var base64 = this.toBase64();
  var data = "sourceMappingURL=data:application/json;charset=utf-8;base64," + base64;
  return options && options.multiline ? "/*# " + data + " */" : "//# " + data;
}; // returns copy instead of original


Converter.prototype.toObject = function () {
  return JSON.parse(this.toJSON());
};

Converter.prototype.addProperty = function (key, value) {
  if (this.sourcemap.hasOwnProperty(key)) throw new Error("property \"" + key + "\" already exists on the sourcemap, use set property instead");
  return this.setProperty(key, value);
};

Converter.prototype.setProperty = function (key, value) {
  this.sourcemap[key] = value;
  return this;
};

Converter.prototype.getProperty = function (key) {
  return this.sourcemap[key];
};

exports.fromObject = function (obj) {
  return new Converter(obj);
};

exports.fromJSON = function (json) {
  return new Converter(json, {
    isJSON: true
  });
};

exports.fromBase64 = function (base64) {
  return new Converter(base64, {
    isEncoded: true
  });
};

exports.fromComment = function (comment) {
  comment = comment.replace(/^\/\*/g, "//").replace(/\*\/$/g, "");
  return new Converter(comment, {
    isEncoded: true,
    hasComment: true
  });
};

exports.fromMapFileComment = function (comment, dir) {
  return new Converter(comment, {
    commentFileDir: dir,
    isFileComment: true,
    isJSON: true
  });
}; // Finds last sourcemap comment in file or returns null if none was found


exports.fromSource = function (content) {
  var m = content.match(exports.commentRegex);
  return m ? exports.fromComment(m.pop()) : null;
}; // Finds last sourcemap comment in file or returns null if none was found


exports.fromMapFileSource = function (content, dir) {
  var m = content.match(exports.mapFileCommentRegex);
  return m ? exports.fromMapFileComment(m.pop(), dir) : null;
};

exports.removeComments = function (src) {
  return src.replace(exports.commentRegex, "");
};

exports.removeMapFileComments = function (src) {
  return src.replace(exports.mapFileCommentRegex, "");
};

exports.generateMapFileComment = function (file, options) {
  var data = "sourceMappingURL=" + file;
  return options && options.multiline ? "/*# " + data + " */" : "//# " + data;
};

export default exports;
export const fromObject = exports.fromObject,
      fromJSON = exports.fromJSON,
      fromBase64 = exports.fromBase64,
      fromComment = exports.fromComment,
      fromMapFileComment = exports.fromMapFileComment,
      fromSource = exports.fromSource,
      fromMapFileSource = exports.fromMapFileSource,
      removeComments = exports.removeComments,
      removeMapFileComments = exports.removeMapFileComments,
      generateMapFileComment = exports.generateMapFileComment;